<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar Explanação" : "Cadastrar Explanação" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col md="6" cols="12">
              <v-file-input
                v-model="data.capa"
                label="Capa"
                counter
                show-size
                truncate-length="15"
              ></v-file-input>
            </v-col>

            <v-col md="6" cols="12">
              <v-file-input
                v-model="data.arquivo"
                label="Arquivos"
                counter
                show-size
                truncate-length="15"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import instanceAxios from "../../plugins/axios";

export default {
  name: "UserForm",

  mounted: async function() {
    const id = this.$route.params.id;
    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }
  },

  components: {},

  data: () => ({
    data: {},
    errors: {},
    listRoles: [],
    loading: false,
    enabledInputs: false,
    ComercialService: new ComercialService(""),
    status: ["Cliente Fechado", "Proposta Enviada", "Agendar Contato"],
  }),

  methods: {
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        const id = this.data.id !== undefined ? "/" + this.data.id : "";

        let dataForm = new FormData();

        dataForm.append("file", this.data.arquivo);
        dataForm.append("capa", this.data.capa);

        await instanceAxios.post("explanacao" + id, dataForm);

        ToastService("Ação realizada com sucesso.", "success");
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    getDate(payload) {
      this.data.data_retorno = payload.date;
    },

    async showData(id) {
      try {
        this.data = await this.ComercialService.show("videos/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.back();
    },
  },
};
</script>
